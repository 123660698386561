import $ from 'jquery';
import lazySizes from 'lazysizes';
import 'lazysizes/plugins/respimg/ls.respimg';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes/plugins/bgset/ls.bgset';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { deviceType } from 'detect-it';
import { Crawler } from 'es6-crawler-detect';

import { $Elements, Animations, Classes, Events, steVarPrefix } from './constants';
import { fixMobileHover, getCookiesAllowed, initGTM, initLightboxes, isDisclaimerAccepted, resetCookiesAllowed, resetGTMConsent, setCookiesAllowed, setDisclaimerAccepted, smoothScrollTo, sortAnimations, wait } from './helpers';

// ---------- LazyLoad Config ----------

lazySizes.cfg.throttleDelay = 66;
lazySizes.cfg.preloadAfterLoad = true;

// ---------- Fix Scroll ----------

history.scrollRestoration = "manual";

// ---------- Touch Screen ----------

if(deviceType === 'touchOnly') {
	$Elements.html.addClass('touchscreen');
}

// ---------- Element Offsets ----------

$Elements.window.on(Events.resize, () => {

	const setElementOffset = (element, name) => $Elements.html.css('--' + steVarPrefix + name, element.outerHeight() + 'px');

	const setHeaderOffset = () => setElementOffset($Elements.HeaderWrapper, 'header-offset');
	setHeaderOffset();
	$Elements.HeaderWrapper.one(Events.transition.end, setHeaderOffset);

	const setFooterOffset = () => setElementOffset($Elements.FooterWrapper, 'footer-offset');
	setFooterOffset();
	$Elements.FooterWrapper.one(Events.transition.end, setFooterOffset);
});

$Elements.window.trigger(Events.resize);

// ---------- jQuery Related ----------

$Elements.document.on(Events.page.enter, async() => {

	// ---------- Fix Mobile Hover ----------

	fixMobileHover();

	// ---------- Handle Aniamtions ----------

	ScrollTrigger.matchMedia(sortAnimations(Animations));
	$Elements.window.on(Events.animation.refresh, () => ScrollTrigger.refresh());

	// ---------- Links ----------

	$Elements.PageWrapper.on(Events.click, 'a[href="#"], .void-link', (e) => e.preventDefault());

	$Elements.PageWrapper.on(Events.click, '[data-scroll-to], [target="scroll-to"]', function(e) {
		e.preventDefault();

		const self = $(this);
		const target = (self.is('a')) ? $(self.attr('href')) : $(self.data('scroll-to'));

		if(target.length) {
			smoothScrollTo(target);
		}
	});

	$Elements.PageWrapper.on(Events.click, '.select-dropdown .dropdown-menu .btn', function() {
		$(this).closest('.dropdown').find('.dropdown-toggle').html($(this).text());
	});

	// ---------- GLightbox ----------

	initLightboxes();

	// ---------- Cookies ----------

	initGTM();

	const initCookiesAllowed = async() => {

		const cookies = document.querySelector('#cookies-allowed');

		if(cookies) {

			cookies.classList.remove(Classes.hidden);
			await wait(100);
			cookies.classList.add(Classes.show);

			$(cookies).on(Events.click, '[data-bs-dismiss="alert"]', function() {
				setCookiesAllowed(this.dataset.allowed);
			});

			$(cookies).on('closed.bs.alert', function() {
				cookies.classList.add(Classes.hidden);
				document.body.append(cookies);
			});
		}
	};

	if(!getCookiesAllowed()) {
		await initCookiesAllowed();
	}

	$('[data-reset-cookies-allowed]').click(async function() {
		resetCookiesAllowed();
		resetGTMConsent();
		await initCookiesAllowed();
	});
});

// ---------- Disclaimer ----------

if(!isDisclaimerAccepted()) {

	const disclaimer = document.querySelector('#disclaimer');
	const CrawlerDetector = new Crawler();

	if(!CrawlerDetector.isCrawler(navigator.userAgent) && disclaimer) {

		disclaimer.classList.remove(Classes.hidden);
		$Elements.body.addClass(Classes.disclaimer);

		$(disclaimer).on('close.bs.alert', function() {
			setDisclaimerAccepted();
			$Elements.body.removeClass(Classes.disclaimer);
		});
	}
}

