import $ from 'jquery';
import Swiper from 'swiper';
import { EffectFade, Navigation } from 'swiper/modules';

import { $Elements, Breakpoints, Durations, Events } from './constants';

$Elements.document.on(Events.page.enter, () => {

	// ---------- Carousel ----------

	$('.introduction-section .section-carousel, .slider-section .section-carousel').each(function() {

		if(!this.swiper) {

			const swiper = new Swiper(this, {
				speed: Durations.animations.ms,
				loop: true,
				observer: true,
				effect: 'fade',
				modules: [EffectFade, Navigation],
				navigation: {
					nextEl: this.querySelector('.swiper-button-next'),
					prevEl: this.querySelector('.swiper-button-prev'),
				},
			});
		}
	});

	// ---------- Single Product ----------

	$('.glightbox-column .column-slideshow').each(function() {

		if(!this.swiper) {

			const swiper = new Swiper(this, {
				speed: Durations.animations.ms,
				observer: true,
				centerInsufficientSlides: true,
				modules: [Navigation],
				loop: $(this).find('.column-image').length > 1,
				slidesPerView: 1,
				breakpoints: {
					[Breakpoints.menu]: {
						loop: $(this).find('.column-image').length > 2,
						slidesPerView: 2,
					}
				},
				navigation: {
					nextEl: this.querySelector('.swiper-button-next'),
					prevEl: this.querySelector('.swiper-button-prev'),
				},
			});
		}
	});
});
