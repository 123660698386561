import $ from 'jquery';
import { gsap } from 'gsap';

import { $Elements, Animations, Classes, Events } from '../global/constants';

// ---------- Init Scroll Animation ----------

export const initScrollAnimations = () => {

	// ---------- Animated Section ----------

	$('.animated-section:not(.transition-clone)').each(function() {

		Animations.global.push(() => {

			const timeline = gsap.timeline({
				scrollTrigger: {
					trigger: this,
					once: true,
					start: 'top 80%',
					toggleClass: Classes.active,
				}
			});
		});
	});

	// ---------- Content Sections ----------

	$('.parallax-section:not(.fixed):not(.transition-clone)').each(function() {

		Animations.mobile.max.push(() => this.classList.remove(Classes.moving));

		Animations.mobile.min.push(() => {

			this.classList.add(Classes.moving);

			const image = this.querySelector(':scope .section-image');
			const speed = parseInt(this.dataset.speed, 10);

			if(image) {

				const timeline = gsap.timeline({
					scrollTrigger: {
						trigger: this,
						start: 'top bottom',
						end: 'bottom top',
						scrub: true,
						invalidateOnRefresh: true,
					},
				});

				const offset = () => image.offsetHeight - ((image.offsetHeight - this.offsetHeight) / 2);

				timeline.fromTo(
					image,
					{ y: () => offset() * (speed / 100), ease: 'none' },
					{ y: () => offset() * (-speed / 100), ease: 'none' }
				);
			}
		});
	});

	// ---------- Menu ----------

	$Elements.HeaderWrapper.each(function() {

		Animations.global.push(() => {

			gsap.timeline({
				scrollTrigger: {
					start: 1,
					end: 'max',
					onUpdate: (self) => {
						(self.direction === -1) ? this.classList.remove(Classes.shrink) : this.classList.add(Classes.shrink);
						(self.progress > 0) ? this.classList.add(Classes.shrinkable) : this.classList.remove(Classes.shrinkable);
					}
				}
			});
		});
	});
};

$Elements.document.on(Events.page.enter, () => initScrollAnimations());
