import $ from 'jquery';
import barba from '@barba/core';

import { $Elements, Classes, Durations, Elements, Events, HasBarba, HasWoocommerce, Links, pauseAnimations, resetAnimations } from '../global/constants';
import { disableBodyScroll, enableBodyScroll, replaceBodyClasses, scrollPosition, smoothScrollTo, wait, waitForImage, waitForVideo } from '../global/helpers';
import { initPageTransition } from './default';

// ---------- Initialisation ----------

if(HasBarba) {

	if(HasWoocommerce) {

		if(document.body.classList.contains('woocommerce-page')) {
			initPageTransition(Links.base);
		}
		else {
			initBarba();
			initPageTransition(Links.shop, false);
		}
	}
	else {
		initBarba();
	}
}

// ---------- Functions ----------

function initBarba() {

	barba.init({
		debug: false,
		timeout: 10000,
		prefetchIgnore: true,
		preventRunning: true,
		prevent: ({ el }) => !$(el).is(Links.barba),
		transitions: [{
			sync: true,
			once: async() => {

				$Elements.document.on('mousedown touchstart', Links.barba, e => {

					if((typeof (window.ontouchstart) !== 'undefined' && e.type === 'mousedown') || (e.which === 2 || e.which === 3 || e.button === 1 || e.button === 2 || e.metaKey || e.shiftKey || navigator.platform.toUpperCase().indexOf('WIN') !== -1 && e.ctrlKey)) return;

					const target = new URL(e.currentTarget.href);

					if(target.pathname !== window.location.pathname) {
						Elements.LoadingBar.classList.add(Classes.loading);
					}
				});

				$Elements.window.on('load', pageLoaded);
				$Elements.document.trigger(Events.page.loaded);
			},
			enter: async() => $Elements.document.one(Events.page.transition, pageLoaded),
			leave: async(data) => {

				Elements.MainWrapper.classList.remove(Classes.initialLoad);
				Elements.LoadingOverlay.classList.remove(Classes.initialLoad);

				document.body.classList.add(Classes.leaving);

				pauseAnimations();

				await defaultTransition(data);

				return true;
			},
			after: async(data) => {

				smoothScrollTo(0, 0, 0);

				replaceBodyClasses(data.next);

				$('.' + Classes.transitionClone).remove();

				$Elements.document.trigger(Events.page.transition);

				return true;
			}
		}]
	});
}

// ---------- Global ----------

async function pageLoaded() {

	resetAnimations();
	$Elements.document.trigger(Events.page.enter);
	Elements.LoadingBar.classList.remove(Classes.loading);
	Elements.MainWrapper.classList.remove(Classes.loading);
	Elements.LoadingOverlay.classList.remove(Classes.loading);

	return true;
}

async function waitForPage(page = Elements.PageWrapper, duration = Durations.page.ms) {

	await wait(duration);

	const heroVideo = page.querySelector(`.${Classes.ContentWrapper} > .content-section:first-child .video`);
	const heroImage = page.querySelector(`.${Classes.ContentWrapper} > .content-section:first-child .image`);

	if(heroVideo) {
		await waitForVideo(heroVideo);
	}
	else if(heroImage) {
		await waitForImage(heroImage);
	}

	return true;
}

async function saveCurrentScrollPosition(data) {

	const scroll = scrollPosition();

	data.current.container.classList.add(Classes.leaving);
	data.current.container.style.top = -scroll.scrollTop + 'px';

	smoothScrollTo(0, 0, 0);

	return true;
}

// ---------- Default Transition ----------

async function defaultTransition(data) {

	Elements.LoadingOverlay.classList.add(Classes.loading);

	await saveCurrentScrollPosition(data);

	disableBodyScroll();

	if(document.body.classList.contains(Classes.menuOpen)) {
		document.body.classList.remove(Classes.menuOpen);
	}

	await waitForPage(data.next.container);

	enableBodyScroll();

	return true;
}
